// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".t7E1m7Bw0rBLbMj0Ln5sJw\\=\\={\n    position: relative;\n    animation: wyj53nWhskVZKaX\\+q1xbGA\\=\\= 200ms ease-in-out;\n}\n\n@keyframes wyj53nWhskVZKaX\\+q1xbGA\\=\\= {\n    0% {\n        left: 100%;\n    }\n    100%{\n        left: 0px;\n    }\n}\n\n.knErc64ROl6IrPPVofAkPQ\\=\\={\n    display: flex;\n    flex-direction: row;\n    justify-content: end;\n    margin-top: 20px;\n    gap: 10px;\n}", "",{"version":3,"sources":["webpack://./src/components/tool-bar/drawerContentComponts/advancedSearchDrawer/attributeTab/AttributeTab.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,wDAA6C;AACjD;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,SAAS;IACb;AACJ;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,oBAAoB;IACpB,gBAAgB;IAChB,SAAS;AACb","sourcesContent":[".container{\n    position: relative;\n    animation: swipe-from-right 200ms ease-in-out;\n}\n\n@keyframes swipe-from-right {\n    0% {\n        left: 100%;\n    }\n    100%{\n        left: 0px;\n    }\n}\n\n.buttonContainer{\n    display: flex;\n    flex-direction: row;\n    justify-content: end;\n    margin-top: 20px;\n    gap: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "t7E1m7Bw0rBLbMj0Ln5sJw==",
	"swipe-from-right": "wyj53nWhskVZKaX+q1xbGA==",
	"buttonContainer": "knErc64ROl6IrPPVofAkPQ=="
};
export default ___CSS_LOADER_EXPORT___;
