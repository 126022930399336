import React from "react";
import FuelsToggle from "../fuelsToggle/FuelsToggle";
import cssClasses from "./FuelsUserInputToggle.module.css";

type Props = {
    label: string
    onChange(flag: boolean): any;
};

function FuelsUserInputToggle({label, onChange}: Props) {
  return (
    <div className={cssClasses.container}>
      <label>{label}</label>
      <FuelsToggle onChange={onChange} size={3}/>
    </div>
  );
}

export default FuelsUserInputToggle;
