import React from "react";
import FuelsButton from "../../../../fuelsButton/FuelsButton";
import FuelsExpandable from "../../../../fuelsExpandable/FuelsExpandable";
import FuelsUserInputText from "../../../../fuelsUserInputText/FuelsUserInputText";
import cssClasses from "./AttributeTab.module.css";

type Props = {};

function AttributeTab({}: Props) {
  return (
    <div className={cssClasses.container}>
      <FuelsExpandable headerText={"SEI ID"}>
        <div>
          <FuelsUserInputText label={"SEI ID"} changeHandler={()=>{}} />
          <div className={cssClasses.buttonContainer}>
            <FuelsButton
              text={"Apply"}
              isPrimary={true}
              onClick={()=>{}}
            />
          </div>
          <div className={cssClasses.results}></div>
        </div>
      </FuelsExpandable>
      <FuelsExpandable headerText={"Site ID"}>
        <div>
          <FuelsUserInputText label={"Site ID"} changeHandler={()=>{}} />
          <div className={cssClasses.buttonContainer}>
            <FuelsButton
              text={"Apply"}
              isPrimary={true}
              onClick={()=>{}}
            />
          </div>
          <div className={cssClasses.results}></div>
        </div>
      </FuelsExpandable>
      <FuelsExpandable headerText={"IP Address"}>
        <div>
          <FuelsUserInputText
            label={"IP Address"}
            changeHandler={()=>{}}
          />
          <div className={cssClasses.buttonContainer}>
            <FuelsButton
              text={"Apply"}
              isPrimary={true}
              onClick={()=>{}}
            />
          </div>
          <div className={cssClasses.results}></div>
        </div>
      </FuelsExpandable>
      <FuelsExpandable headerText={"POS Type"}>
        <div>
          <FuelsUserInputText label={"POS Type"} changeHandler={()=>{}} />
          <div className={cssClasses.buttonContainer}>
            <FuelsButton
              text={"Apply"}
              isPrimary={true}
              onClick={()=>{}}
            />
          </div>
          <div className={cssClasses.results}></div>
        </div>
      </FuelsExpandable>
      <FuelsExpandable headerText={"Brand"}>
        <div>
          <FuelsUserInputText label={"Brand"} changeHandler={()=>{}} />
          <div className={cssClasses.buttonContainer}>
            <FuelsButton
              text={"Apply"}
              isPrimary={true}
              onClick={()=>{}}
            />
          </div>
          <div className={cssClasses.results}></div>
        </div>
      </FuelsExpandable>
    </div>
  );
}

export default AttributeTab;
