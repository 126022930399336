import React, { useState } from "react";
import FuelsDropdown from "../../../fuelsDropdown/FuelsDropdown";
import FuelsMultiselect from "../../../fuelsMultiselect/FuelsMultiselect";
import FuelsSeparator from "../../../fuelsSeparator/FuelsSeparator";
import FuelsUserInputToggle from "../../../fuelsUserInputToggle/FuelsUserInputToggle";
import cssClasses from "./OptionsDrawer.module.css";

type Props = {};

export default function OptionsDrawer({}: Props) {
  const [openPopUp, setOpenPopUp] = useState(false);
  return (
    <>
      <h5>Options</h5>
      <div className={cssClasses.content}>
        {/* <FuelsDropdown
          optionList={["Fuel"]}
          handleDropdownChange={()=>{}}
          label={"Context"}
        /> */}
        <FuelsDropdown
          optionList={[{name:"All Products"}, {name:"Gasoline"},{ name:"Diesel"}]}
          handleDropdownChange={()=>{}}
          label={"Product"}
        />
        <FuelsSeparator />
        <FuelsSeparator />
        <FuelsSeparator />
        <FuelsDropdown
          optionList={[
            {name:"Profit"},
            {name:"Volume"},
            {name:"CPG"},
            {name:"Cluster Count"},
            {name:"Price"},
            {name:"Price Offline Count"},
          ]}
          handleDropdownChange={()=>{}}
          label={"KPI"}
        />
        <FuelsSeparator />
        <FuelsSeparator />
        <FuelsSeparator />
        <FuelsUserInputToggle
          label={"Hide Competitors"}
          onChange={()=>{}}
        />
        <FuelsSeparator />
        <FuelsUserInputToggle label={"Hide Own-Sites"} onChange={()=>{}} />
        <FuelsSeparator />
        <FuelsUserInputToggle label={"Show Traffic"} onChange={()=>{}} />
        <FuelsSeparator />
        <FuelsUserInputToggle
          label={"Show all in $USD"}
          onChange={()=>{}}
        />
        <FuelsSeparator />
        <FuelsSeparator />
        <FuelsSeparator />
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between"
        }}>
          <label htmlFor="">Select region</label>
          <FuelsMultiselect
            values={[
              {
                text: "USA",
                selected: true,
              },
              {
                text: "Canada",
                selected: false,
              },
            ]}
            clickHandler={(x) => {}}
            size={0.8}
          />
        </div>
      </div>
    </>
  );
}
