import React from "react";
import FuelsButton from "../../../../fuelsButton/FuelsButton";
import FuelsDropdown from "../../../../fuelsDropdown/FuelsDropdown";
import FuelsMultiselectDropdown from "../../../../fuelsMultiselectDropdown/FuelsMultiselectDropdown";
import FuelsSeparator from "../../../../fuelsSeparator/FuelsSeparator";
import FuelsUserInputText from "../../../../fuelsUserInputText/FuelsUserInputText";
import cssClasses from "./AlertConfiguration.module.css";
type Props = {};

function AlertConfiguration({}: Props) {
  return (
    <div>
      <FuelsSeparator />
      <div className={cssClasses.checkboxContainer}>
        <div>
          <label htmlFor="ActionableAlert">Enable Actionable Alert</label>
          <input
            type="checkbox"
            name="ActionableAlert"
            id="ActionableAlert"
            //   checked={isSameForTxnPrdMapping}
            //   onChange={(e) => setIsSameForTxnPrdMapping(e.target.checked)}
          />
        </div>
        <div>
          <label htmlFor="InformationalAlert">Enable Informational Alert</label>
          <input
            type="checkbox"
            name="InformationalAlert"
            id="InformationalAlert"
            //   checked={isSameForTxnPrdMapping}
            //   onChange={(e) => setIsSameForTxnPrdMapping(e.target.checked)}
          />
        </div>
        {/* <FuelsButton text={"Add"} isPrimary={false} onClick={()=>{}} /> */}
      </div>
      <FuelsSeparator />

      <FuelsMultiselectDropdown
        optionList={["09:00", "09:30"]}
        handleDropdownChange={()=>{}}
        label={"Schedule Time"}
      />
      <FuelsSeparator />
      <FuelsSeparator />
      <FuelsSeparator />
      <FuelsDropdown
        optionList={[
          { name: "Informational Alert" },
          { name: "Actionable Alert" },
        ]}
        handleDropdownChange={()=>{}}
        label={"Alert"}
      />
      <FuelsUserInputText label={"Email"} changeHandler={()=>{}} />
      <FuelsUserInputText label={"Name"} changeHandler={()=>{}} />
    </div>
  );
}

export default AlertConfiguration;
