import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import cssClasses from "./FuelsMultiselect.module.css";

/**
 * TODO 1: if this component's parent component is calling any api
 *          on clicking the buttons then the parent component
 *          should implement the cancelletion of earlier request.
 *          check the following video.
 *          https://www.youtube.com/watch?v=cIwpavIhI84
 * TODO 2: add animation on Hover, on check, on uncheck
 * TODO 3: add more optional props later
 * TODO 4: default selected values
 * TODO 5: add props to add icon optionally.
 *
 */

type Value = {
  text: string;
  selected: boolean;
};

type Props = {
  values: Value[];
  clickHandler(countryList: string[]): any;
  defaultValues?: string[];
  size?: number;
  className?: string;
  //[propName: string]: {}; //this is done to receive rest of the props like id, width, height etc from parent
};

/**
 * @props   `values` array of "Value" type to show as buttons. "Value" type: {"text": string; "selected": boolean;};
 * @props   `clickHandler` to be called on click of the buttons
 * @props   `size` (optional) size of the component
 * @props   `className` (optional) use this classname in css to position the component with in the parent component
 * @author Anirban Maji
 *
 * this component takes in one array of string and
 * one callback function (clickHandler) to be executed on the selected values.
 * no child parameter should be passed.
 *
 */
export default function FuelsMultiselect({
  values,
  clickHandler,
  size,
  className,
  defaultValues,
}: Props): JSX.Element {
  const containerRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  // let selected: string[] = [];
  const [stateValues, setStateValues] = useState(values);
  function handleCheckbox(event: ChangeEvent<HTMLInputElement>) {
    setStateValues((stateValues) => [
      ...stateValues.map((v) => {
        if (v.text === event.target.value) v.selected = event.target.checked;
        return v;
      }),
    ]);
  }

  useEffect(() => {
    if (size)
      containerRef.current.style.setProperty("--textFontSize", size + "rem");
  }, [containerRef, size]);

  useEffect(() => {
    clickHandler(stateValues.filter((v) => v.selected).map((v) => v.text));
  }, [stateValues]);

  return (
    <div
      ref={containerRef}
      className={className + " " + cssClasses.checkboxContainer}
    >
      {stateValues.map((x, i) => {
        return (
          <div className={cssClasses.checkboxitem} key={x.text}>
            <input
              className={cssClasses.checkbox}
              onChange={handleCheckbox}
              type="checkbox"
              id={x.text}
              name={x.text}
              value={x.text}
              checked={x.selected}
            />
            <label
              className={
                i === 0
                  ? cssClasses.checkboxTextFirst
                  : i === values.length - 1
                  ? cssClasses.checkboxTextLast
                  : cssClasses.checkboxText
              }
              htmlFor={x.text}
            >
              {x.text}
            </label>
          </div>
        );
      })}
    </div>
  );
}
