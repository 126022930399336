import { Autocomplete, TextField } from "@mui/material";
import { debounce } from "lodash";
import {isEqual} from "lodash";

import React, { useCallback, useEffect, useRef, useState } from "react";
import cssClasses from "./FuelsDropdown.module.css";

/**
 * TODO 1: take an optional prop(number) which will denote the index of default selected option
 * TODO 2: typying while in focus would highlight the option
 * TODO 3: add a required flag
 * TODO 4: add props to add icon optionally.
 * TODO 5: add a placeholder text optionally.
 * TODO 6: every dropdown option can have an icon
 */

type Option = {
  name: string;
  icon?: string
}

type Props = {
  optionList: Option[];
  handleDropdownChange: (selected) => void;
  label: string;
};

function FuelsDropdown({ optionList, handleDropdownChange, label }: Props) {
  // const [options, setOptions] = useState([...optionList]);
  const [inputValue, setInputValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const optionsDropdownRef = useRef(null);
  const [isfocused, setIsfocused] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(0);

  // useEffect(() => {
  //   getOptions(inputValue, setOptions);
  // }, [inputValue]);

  useEffect(() => {
    if (showDropdown) {
      elementInViewport();
    }
  }, [showDropdown]);
  useEffect(() => {
    handleDropdownChange(inputValue);
  }, [inputValue]);

  function elementInViewport() {
    var bounding = optionsDropdownRef.current.getBoundingClientRect();

    if (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth) &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight)
    ) {
      // console.log("Element is in the viewport!");
    } else {
      // console.log("Element is NOT in the viewport!");
    }
  }

  function handleClick() {
    if (!isfocused) {
      setShowDropdown((b) => !b);
    }
  }

  function handleFocus() {
    setIsfocused(true);
    setTimeout(() => {
      setIsfocused(false);
    }, 300);
    setShowDropdown(() => true);
  }
  function handleBlur() {
    setShowDropdown(false);
  }

  function select(optionName: string) {
    setShowDropdown(false);
    setInputValue(optionName);
  }

  function handleKeyPress(e: any) {
    const optionsLenth = optionList.length;
    switch (e.keyCode) {
      case 38:

        setHoveredIndex((x) => {
          if(x<=0){
            return optionsLenth-1;
          }
          return (x - 1)
        });
        break;
      case 40:
        setHoveredIndex((x) => {
          if(x>=optionsLenth-1){
            return 0;
          }
          return (x + 1)
        });
        break;
      case 13:
        select(optionList[hoveredIndex].name);
    }
  }

  return (
    <div className={cssClasses.container}>
      <label>{label}</label>
      <div className={cssClasses.itextInputContainer}>
        <div
          id="option"
          onClick={handleClick}
          tabIndex={0}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyPress}
          className={cssClasses.dropTrigger}
        >
          {inputValue}
        </div>

        <p className={cssClasses.triangle}>&#9660;</p>
      </div>

      {showDropdown && (
        <div
          className={cssClasses.optionsDropdown}
          id="optionDropdown"
          ref={optionsDropdownRef}
        >
          {optionList.map((option, ind) => {
            return (
              <div
                key={option.name}
                onMouseDown={() => select(option.name)}
                onMouseOver={() => setHoveredIndex(ind)}
                className={
                  option.name === optionList[hoveredIndex].name
                    ? cssClasses.optionListHovered
                    : cssClasses.optionList
                }
              >
                {option.name}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default FuelsDropdown;
