import React from "react";
import FuelsButton from "../../../../fuelsButton/FuelsButton";
import FuelsExpandable from "../../../../fuelsExpandable/FuelsExpandable";
import FuelsMultiselectDropdown from "../../../../fuelsMultiselectDropdown/FuelsMultiselectDropdown";
import FuelsNavigation from "../../../../fuelsNavigation/FuelsNavigation";
import FuelsSeparator from "../../../../fuelsSeparator/FuelsSeparator";
import FuelsSiteCard from "../../../../fuelsSiteCard/FuelsSiteCard";
import FuelsUserInputSlider from "../../../../fuelsUserInputSlider/FuelsUserInputSlider";
import FuelsUserInputText from "../../../../fuelsUserInputText/FuelsUserInputText";
import cssClasses from "./LandmarkTab.module.css";

type Props = {};

function LandmarkTab({}: Props) {
  return (
    <div className={cssClasses.container}>
      <FuelsExpandable headerText={"Search Parameters"} isExpanded={true}>
        <div>
          <FuelsUserInputText
            label={"Landmark"}
            changeHandler={()=>{}}
            placeholder={"address, city, country, state or zip code"}
          />
          <FuelsSeparator />
          <FuelsUserInputSlider
            label={"Miles(5-250)"}
            onSlide={()=>{}}
            start={5}
            end={250}
          />
          <FuelsSeparator />
          <FuelsMultiselectDropdown
            optionList={[
              "AAFES",
              "AC & T",
              "ACME MARKETS",
              "ADMIRAL",
              "AFD",
              "AL PRIME",
              "FRYS",
            ]}
            handleDropdownChange={()=>{}}
            label={"Select Brand"}
          />
          <FuelsSeparator />
          <FuelsMultiselectDropdown
            optionList={["Verifone", "Gilbarco", "Bulloch", "DEX", "Other"]}
            handleDropdownChange={()=>{}}
            label={"Select POS"}
          />
          <div className={cssClasses.buttonContainer}>
            <FuelsButton
              text={"reset"}
              isPrimary={false}
              onClick={()=>{}}
            />
            <FuelsButton
              text={"Apply"}
              isPrimary={true}
              onClick={()=>{}}
            />
          </div>
        </div>
      </FuelsExpandable>

      <label>Results</label>
      <div className={cssClasses.cardsContainer}>
        <FuelsSiteCard isCompetitor={true} isSelected={false} />
        <FuelsSiteCard isCompetitor={false} isSelected={true} />
      </div>
      <FuelsNavigation size={5} showInAPage={5} currentRange={()=>{}}/>
    </div>
  );
}

export default LandmarkTab;
