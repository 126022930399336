import React, { useEffect, useState } from "react";
import cssClasses from "./FuelsUserInputText.module.css";

/**
 * TODO 1: change text field clearing icon to actual icon instead of "X"
 * TODO 2: put an enabled/disabled flag
 * TODO 3: keep a required flag
 * TODO 4: keep a password flag, if this is true then render password input
 */

type Props = {
  label: string;
  changeHandler(userInput: string): any;
  inputRef?: React.MutableRefObject<any>;
  placeholder?: string;
  isPassword?: boolean;
};

function FuelsUserInputText({ label, changeHandler, inputRef, placeholder, isPassword }: Props) {
  const [userInput, setUserInput] = useState("");
  useEffect(() => {
    changeHandler(userInput);
  }, [changeHandler, userInput]);

  return (
    <div className={cssClasses.container}>
      <label htmlFor="">{label}</label>
      <div className={cssClasses.itextInputContainer}>
        <input
          className={cssClasses.itextInput}
          type={isPassword? "password":"text"}
          name=""
          id=""
          value={userInput}
          placeholder={placeholder? placeholder: label}
          onChange={(e) => setUserInput(e.target.value)}
          ref={inputRef}
        />
        {userInput && (
          <p
            className={cssClasses.itextInputClear}
            onClick={() => setUserInput("")}
          >
            X
          </p>
        )}
      </div>
    </div>
  );
}

export default FuelsUserInputText;
