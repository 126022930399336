import React from 'react'
import cssClasses from './FuelsSiteCard.module.css'

type Props = {
    isCompetitor: boolean,
    isSelected : boolean
}

function FuelsSiteCard({isCompetitor, isSelected}: Props) {
  return (<div className={isSelected?cssClasses.selectedContainer:cssClasses.unselectedContainer}>
    <div className={isCompetitor? cssClasses.compContainer : cssClasses.ownContainer} >
        <img className={cssClasses.gasBrandLogo} src="" alt="" />
        <h5 className={cssClasses.siteName}>jskfdafalfd</h5>
        <p className={cssClasses.price}>$30.5<sup>7</sup></p>
        <div className={cssClasses.icons}>icons</div>
    </div>

  </div>
  )
}

export default FuelsSiteCard