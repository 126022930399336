// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pJ3rOyWeMu71FRDK5JjzJg\\=\\={\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n}", "",{"version":3,"sources":["webpack://./src/components/tool-bar/drawerContentComponts/addSiteDrawer/alertConfiguration/AlertConfiguration.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC","sourcesContent":[".checkboxContainer{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxContainer": "pJ3rOyWeMu71FRDK5JjzJg=="
};
export default ___CSS_LOADER_EXPORT___;
