import React, { useCallback, useEffect, useRef, useState } from "react";
import cssClasses from "./FuelsUserInputSlider.module.css";
import { debounce } from "lodash";
/**
 * TODO 1: should be able to type the desired number
 * TODO 2: start, end and default value should come as a props
 * TODO 3: a mover pointer should appear in the seperation line
 */

type Props = {
  label : string;
  start: number;
  end: number;
  onSlide(value: number): any;
};

function FuelsUserInputSlider({label, start, end, onSlide}: Props) {
  const [sliderValue, setSliderValue] = useState(40);
  const containerRef = useRef<HTMLDivElement>(null);
  const valueToPercentage = (100 * (sliderValue - start)) / (end - start);

  useEffect(() => {
    if (containerRef.current != null)
      containerRef.current.style.setProperty(
        "--pointerPosition",
        valueToPercentage + "%"
      );
  }, [containerRef, valueToPercentage]);

  const getOptions = useCallback(
    debounce((value) => {
      onSlide(-(-value))
    }, 200),
    []
  );

  useEffect(() => {
    getOptions(sliderValue);
  }, [sliderValue]);

  return (
    <div ref={containerRef} className={cssClasses.container}>
      <div style={{ paddingBottom: "24px" }}>
      <label htmlFor="">{label}</label>
      </div>

      <label className={cssClasses.range}>{start}</label>
      <div className={cssClasses.mainSlider}>
        <input
          className={cssClasses.slider}
          type="range"
          name=""
          id=""
          value={sliderValue}
          min="5"
          max="250"
          onChange={(e) => setSliderValue(-(-e.target.value))}
        />
        <div className={cssClasses.movable}></div>
        <label className={cssClasses.valuePointer}>{sliderValue}</label>
      </div>
      <label className={cssClasses.range}>{end}</label>
    </div>
  );
}

export default FuelsUserInputSlider;
