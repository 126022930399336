import { Box, Tabs, Tab } from "@mui/material";
import React, { useState } from "react";
import cssClasses from "./AdvancedSearchDrawer.module.css";
import AttributeTab from "./attributeTab/AttributeTab";
import LandmarkTab from "./landmarkTab/LandmarkTab";

/**
 * TODO 1: create custom tabs instead of material tab
 */

type Props = {};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

const searchOptions = [
  {name: "Attribute", component: <AttributeTab/>},
  {name: "Landmark", component: <LandmarkTab/>},
]
// const searchOptions = [
//   {name: "Item One", component: <div>Landmark</div>},
//   {name: "Item Two", component: <div>Attribute</div>},
//   {name: "Item Three", component: <div>Attribute</div>}
// ]

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

export default function AdvancedSearchDrawer({}: Props) {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <div>
      <h5>Advanced Search</h5>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs variant="fullWidth"
          TabIndicatorProps={{style: {background:'green'}}}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {
          searchOptions.map((so, i)=>
          
          // <Tab label={<span style={{ color: 'green' }}>{so.name}</span>} {...a11yProps(i)} />
          <Tab label={so.name} {...a11yProps(i)} key={so.name}/>
          )
        }
        </Tabs>
      </Box>

      {
          searchOptions.map((so, i)=><TabPanel value={value} index={i} key={so.name}>
          {so.component}
        </TabPanel>)
        }
    </div>
  );
}
