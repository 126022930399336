import { useEffect } from "react";
import ToolBar from "./components/tool-bar/ToolBar";
import WebFont from "webfontloader";
import { CustomProps } from "./utils/dataDefinitions/dataTypes";

type Props = {
  customProps: CustomProps;
};


export default function Root({customProps}:Props) {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Roboto", "Arial"],
      },
    });
  }, []);
  // useEffect(() => {
  //   console.log("visops toolbar props", customProps);
  // }, [customProps]);
  return <ToolBar getLocationByBounds={customProps.getLocationByBounds} setCenter={customProps.setCenter} getStoreListViewData={customProps.getStoreListViewData}/>;
}
