import React from 'react'
import cssClasses from './FuelsSeparator.module.css'
type Props = {}

function FuelsSeparator({}: Props) {
  return (
    <div className={cssClasses.separator}></div>
  )
}

export default FuelsSeparator