// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zQM17Y91vY1PVU4dgAj3uQ\\=\\= {\n  width: 100%;\n  height: 2px;\n  /* border-radius: 1px; */\n  /* background-color: hsl(0, 0%, 90%); */\n  /* box-shadow: inset -2px -2px 2px 1px hsla(0, 0%, 100%, 0.5),\n    inset 2px 2px 2px 1px hsl(215, 61%, 16%, 0.2),\n    inset -1px -1px 1px 0px hsla(0, 0%, 100%, 0.5),\n    inset 1px 1px 1px 0px hsl(215, 61%, 16%, 0.2); */\n  margin: 4px auto ;\n}\n", "",{"version":3,"sources":["webpack://./src/components/fuelsSeparator/FuelsSeparator.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,WAAW;EACX,wBAAwB;EACxB,uCAAuC;EACvC;;;oDAGkD;EAClD,iBAAiB;AACnB","sourcesContent":[".separator {\n  width: 100%;\n  height: 2px;\n  /* border-radius: 1px; */\n  /* background-color: hsl(0, 0%, 90%); */\n  /* box-shadow: inset -2px -2px 2px 1px hsla(0, 0%, 100%, 0.5),\n    inset 2px 2px 2px 1px hsl(215, 61%, 16%, 0.2),\n    inset -1px -1px 1px 0px hsla(0, 0%, 100%, 0.5),\n    inset 1px 1px 1px 0px hsl(215, 61%, 16%, 0.2); */\n  margin: 4px auto ;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"separator": "zQM17Y91vY1PVU4dgAj3uQ=="
};
export default ___CSS_LOADER_EXPORT___;
