import React from "react";
import cssClasses from "./FuelsButton.module.css";

/**
 * TODO 1: add icon
 */

type Props = {
  text: string;
  isPrimary: boolean;
  onClick: ()=>void;
  icon?: string;
};

/**
 * TODO 1: add props to add icon optionally.
 * TODO 2: add isDisabled Property
 * @param param0 
 * @returns 
 */

function FuelsButton({ text, isPrimary, onClick }: Props) {
  return (
    <button
      className={
        isPrimary ? cssClasses.primaryButton : cssClasses.secondaryButton
      }
      onClick={onClick}
    >
      {text}
    </button>
  );
}

export default FuelsButton;
