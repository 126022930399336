import React, { useState } from "react";
import FuelsButton from "../../../../fuelsButton/FuelsButton";
import FuelsDropdown from "../../../../fuelsDropdown/FuelsDropdown";
import FuelsUserInputText from "../../../../fuelsUserInputText/FuelsUserInputText";
import cssClasses from "./ProductMapping.module.css";
type Props = {};

function ProductMapping({}: Props) {
  const [isSameForTxnPrdMapping, setIsSameForTxnPrdMapping] = useState(false);
  return (
    <div>
      <div>
        <div className={cssClasses.checkboxANdButton}>
          <div>
            <label htmlFor="sameTxnProduct">
              Same for Transaction Product Mapping
            </label>
            <input
              type="checkbox"
              name="sameTxnProduct"
              id="sameTxnProduct"
              checked={isSameForTxnPrdMapping}
              onChange={(e) => setIsSameForTxnPrdMapping(e.target.checked)}
            />
          </div>
          <FuelsButton text={"Add"} isPrimary={false} onClick={()=>{}} />
        </div>

        <div className={cssClasses.inputFieldGroupsContainer}>
          <div className={cssClasses.inputFieldGroup}>
            <h6>Price Update Product Mapping</h6>
            <FuelsDropdown optionList={[{name: "020 - GMB"},{name: "001 - Regular"}]} handleDropdownChange={()=>{}} label={"Product Name"}/>
            <FuelsUserInputText
              label={"Store Product ID"}
              changeHandler={()=>{}}
            />
            <FuelsUserInputText
              label={"Store Product Name"}
              changeHandler={()=>{}}
            />
          </div>
          {!isSameForTxnPrdMapping && (
            <div className={cssClasses.inputFieldGroup}>
              <h6>Transaction Product Mapping</h6>
              <FuelsDropdown optionList={[{name: "020 - GMB"},{name: "001 - Regular"}]} handleDropdownChange={()=>{}} label={"Product Name"}/>
              <FuelsUserInputText
                label={"Store Product ID"}
                changeHandler={()=>{}}
              />
              <FuelsUserInputText
                label={"Store Product Name"}
                changeHandler={()=>{}}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductMapping;
