import React, { useContext, useEffect, useState } from "react";
import cssClasses from "./ToolBar.module.css";
import listView from "../../resources/listView.svg";
import advancedSearch from "../../resources/advancedSearch.svg";
// import customCluster from "../../resources/customCluster.svg";
import addSite from "../../resources/addSite.svg";
import options from "../../resources/options.svg";
import visopsLogo from "../../resources/visops.svg";
import sElevenLogo from "../../resources/7Eleven.svg";
import MenuButton from "./menuButton/MenuButton";
import AdvancedSearchDrawer from "./drawerContentComponts/advancedSearchDrawer/AdvancedSearchDrawer";
import ListViewDrawer from "./drawerContentComponts/listViewDrawer/ListViewDrawer";
import OptionsDrawer from "./drawerContentComponts/optionsDrawer/OptionsDrawer";
import AddSiteDrawer from "./drawerContentComponts/addSiteDrawer/AddSiteDrawer";
import FuelsDrawer from "../fuelsDrawer/FuelsDrawer";
import constants from "../../utils/constants.json";
import {
  CustomProps,
  ListViewEventData,
} from "../../utils/dataDefinitions/dataTypes";
import Parcel from "single-spa-react/parcel";
import {
  GetLocationByBounds,
  GetStoreListViewData,
  SetCenter,
} from "../../utils/dataDefinitions/functionTypes";
// ES6
import Resizable from "react-resizable-box";

/**
 * TODO 1: make the map interactive while the drawer is opened.
 *         to do that I need to remove the Backdrop, clicking on which closes the drawer.
 *         the closing functionality will be moved to the close(cross at right top corner)
 */

type Props = {
  getLocationByBounds: GetLocationByBounds;
  setCenter: SetCenter;
  getStoreListViewData: GetStoreListViewData;
};

type Tool = {
  toolName: string;
  iconSrc: string;
  component: JSX.Element;
};

const emptyTool: Tool = {
  toolName: "",
  iconSrc: "",
  component: null,
};

function ToolBar({
  getLocationByBounds,
  setCenter,
  getStoreListViewData,
}: Props) {
  const [activeTool, setActiveTool] = useState<{
    toolName: string;
    iconSrc: string;
    component: JSX.Element;
  }>(emptyTool);
  const [mapViewChangeDetail, setMapViewChangeDetail] =
    useState<ListViewEventData>(null);

  useEffect(() => {
    //TODO move this event destructuring to the toolbar mfe and pass the detail as a pro
    document.addEventListener(
      constants.eventList.mapViewportUpdate,
      function (e: CustomEvent<ListViewEventData>) {
        // console.log("details", e.detail);
        setMapViewChangeDetail(e.detail);
      },
      false
    );
    return () => {
      document.removeEventListener(
        constants.eventList.mapViewportUpdate,
        () => {}
      );
    };
  }, []);

  // const toggleDrawer =
  //   (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
  //     if (
  //       event.type === "keydown" &&
  //       ((event as React.KeyboardEvent).key === "Tab" ||
  //         (event as React.KeyboardEvent).key === "Shift")
  //     ) {
  //       return;
  //     }

  //     setActiveTool(emptyTool);
  //   };

  const tools = [
    {
      toolName: "List View",
      iconSrc: listView,
      component: (
        <Parcel
          config={() => System.import("@app/visops-list-view")}
          customProps={{
            getLocationByBounds: getLocationByBounds,
            setCenter: setCenter,
            getStoreListViewData: getStoreListViewData,
            initMapViewChangeDetail: mapViewChangeDetail,
          }}
        />
      ),
      isDisabled: false,
    },
    {
      toolName: "Advanced Search (TBD)",
      iconSrc: advancedSearch,
      component: <AdvancedSearchDrawer />,
      isDisabled: true,
    },
    // {
    //   toolName: "custom cluster",
    //   iconSrc: customCluster,
    //   component: <AdvancedSearchDrawer />,
    // },
    {
      toolName: "Add Site (TBD)",
      iconSrc: addSite,
      component: <AddSiteDrawer />,
      isDisabled: true,
    },
    {
      toolName: "Options/Settings (TBD)",
      iconSrc: options,
      component: <OptionsDrawer />,
      isDisabled: true,
    },
  ];

  function close() {
    setActiveTool(emptyTool);
  }

  function handleClick(tool) {
    const x = tool.toolName;
    if (x === activeTool.toolName) {
      //when user clicks on the same icon twice
      setActiveTool(emptyTool);
      return;
    }
    if (activeTool.toolName === "") {
      //when user clicks on an icon
      setActiveTool(tool); //TODO enable this to bring the drawer back
      return;
    }
    //when one drawer is already opened and user clicks on another icon
    setActiveTool(tool); //TODO enable this to bring the drawer back
  }

  return (
    <div
      className={
        activeTool?.toolName !== ""
          ? cssClasses.containerExpanded
          : cssClasses.container
      }
    >
      <div className={cssClasses.toolContainer}>
        <img
          className={cssClasses.visopsLogo}
          src={visopsLogo}
          alt={"visops logo"}
          height="30px"
        />
        {tools.map((t) => (
          <MenuButton
            key={t.toolName}
            toolTipText={t.toolName}
            iconSrc={t.iconSrc}
            isSelected={activeTool?.toolName === t.toolName}
            handleClick={t.isDisabled ? () => {} : () => handleClick(t)}
            isDisabled={t.isDisabled}
          />
        ))}
        <div
          style={{
            position: "relative",
            margin: "10px auto",
          }}
        ></div>
      </div>
    <Resizable className="resizable" width={"100%"} height={"100%"} minWidth={400} maxWidth={window.innerWidth - 200}
    enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}>
      {/* <div className={cssClasses.drawerContainer}> */}
      <FuelsDrawer isOpen={activeTool?.toolName !== ""} close={close}>
        {activeTool?.component}
      </FuelsDrawer>
    </Resizable>
      {/* </div> */}

      <div className={cssClasses.sElevenLogoContainer}>
        <img
          className={cssClasses.sElevenLogo}
          src={sElevenLogo}
          alt={"7 eleven logo"}
          height="60px"
        />
      </div>
    </div>
  );
}

export default ToolBar;
