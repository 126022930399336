import React from 'react'
import cssClasses from './FuelsDrawer.module.css'
import cross from "../../resources/cross.svg";

type Props = {
    children: JSX.Element;
    isOpen: boolean;
    close: () => void;
}

function FuelsDrawer({children, isOpen, close}: Props) {
  return (
    <div className={isOpen ? cssClasses.container : cssClasses.containerHidden}>
        <div className={cssClasses.close} onClick={close}><img
          className={cssClasses.closeIcon}
          src={cross}
          alt={"cross"}
          height="14px"
        /></div>
        {children}
    </div>
  )
}

export default FuelsDrawer