import React, { useEffect, useState } from "react";
import cssClasses from "./MenuButton.module.css";

/**
 * TODO 1: create primary, seondary button
 * TODO 2: take in tooltip, sliding component and icon url as props
 */

type Props = {
  toolTipText: string;
  iconSrc: string;
  isSelected: boolean;
  handleClick: () => void;
  isDisabled: boolean
};

function MenuButton({ toolTipText, iconSrc, isSelected, handleClick, isDisabled }: Props) {
  return (
    <div className={cssClasses.container}>
      <div
        className={
          isSelected
            ? cssClasses.pressedmenuButton
            : cssClasses.elevatedmenuButton
        }
        onClick={handleClick}
      >
        <img
          className={isDisabled ? cssClasses.disabledIcon : cssClasses.icon}
          src={iconSrc}
          alt={toolTipText}
          height="23px"
        />
        <p className={cssClasses.tooltip}>{toolTipText}</p>
      </div>
    </div>
  );
}

export default MenuButton;
