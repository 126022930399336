import React, { useState } from "react";
import FuelsButton from "../../../fuelsButton/FuelsButton";
import FuelsDropdown from "../../../fuelsDropdown/FuelsDropdown";
// import FuelsExpandable from "../../../fuelsExpandable/FuelsExpandable";
import FuelsExpandable from "../../../fuelsExpandable/FuelsExpandable";
import FuelsUserInputText from "../../../fuelsUserInputText/FuelsUserInputText";
import FuelsUserInputToggle from "../../../fuelsUserInputToggle/FuelsUserInputToggle";
import cssClasses from "./AddSiteDrawer.module.css";
import AlertConfiguration from "./alertConfiguration/AlertConfiguration";
import ProductMapping from "./productMapping/ProductMapping";
import locationIcon from "../../../../resources/location.svg";
import connection from "../../../../resources/wifi.svg";
import briefcaseIcon from "../../../../resources/briefcase.svg";
import productMap from "../../../../resources/productMap.svg";
import notification from "../../../../resources/notification.svg";

/**
 * TODO 1: create the reset functionality
 */

type Props = {};

const formComponent = [
  ["Location details", locationIcon],
  ["Connection", connection],
  ["Business Functions", briefcaseIcon],
  ["Product Mapping", productMap],
  ["Alert Configuration", notification],
];

export default function AddSiteDrawer({}: Props) {
  const [currentFormComponentIndex, setCurrentFormComponentIndex] = useState(0);
  return (
    <div className={cssClasses.container}>
      <h5>Add a site</h5>
      <div className={cssClasses.content}>
        <nav className={cssClasses.navigationTab}>
          {formComponent.map((fc, ind) => {
            return (
              <div key={fc[0]}
                onClick={() => setCurrentFormComponentIndex(ind)}
                className={
                  ind === currentFormComponentIndex
                    ? cssClasses.activeTab
                    : cssClasses.tab
                }
                title={fc[0]}
              >
                <img className={cssClasses.icon} src={fc[1]} height="26px" />
                {/* <FuelsToolTip text={fc}/> */}
                {ind === currentFormComponentIndex ? (
                  <span>{fc[0]}</span>
                ) : null}
              </div>
            );
          })}
        </nav>

        <div className={cssClasses.components}>
          {currentFormComponentIndex == 0 && (
            <div>
              <FuelsExpandable headerText={"Site Details"}>
                <>
                  <FuelsUserInputText
                    label={"Site ID"}
                    changeHandler={()=>{}}
                  />
                  <FuelsUserInputText
                    label={"Name"}
                    changeHandler={()=>{}}
                  />
                  <FuelsUserInputText
                    label={"Description"}
                    changeHandler={()=>{}}
                  />
                  <FuelsDropdown
                    optionList={[
                      { name: "AAFES" },
                      { name: "AC&T" },
                      { name: "AFD" },
                    ]}
                    handleDropdownChange={()=>{}}
                    label={"Brand"}
                  />
                  <FuelsDropdown
                    optionList={[
                      { name: "711" },
                      { name: "76" },
                      { name: "alert" },
                      { name: "citgo" },
                    ]}
                    handleDropdownChange={()=>{}}
                    label={"Icon"}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <FuelsUserInputText
                      label={"Latitude"}
                      changeHandler={()=>{}}
                    />
                    <FuelsUserInputText
                      label={"Longitude"}
                      changeHandler={()=>{}}
                    />
                  </div>
                  <FuelsUserInputText
                    label={"OPIS ID"}
                    changeHandler={()=>{}}
                  />
                </>
              </FuelsExpandable>

              <FuelsExpandable headerText={"Address"}>
                <div>
                  <FuelsDropdown
                    optionList={[{ name: "United States" }, { name: "Canada" }]}
                    handleDropdownChange={()=>{}}
                    label={"Country"}
                  />
                  <FuelsUserInputText
                    label={"Street Address"}
                    changeHandler={()=>{}}
                  />
                  <FuelsUserInputText
                    label={"City"}
                    changeHandler={()=>{}}
                  />

                  {/* add State/Province dynamically once the country is selected */}
                  <FuelsDropdown
                    optionList={[
                      { name: "WA(Washington)" },
                      { name: "AL(Alabama)" },
                    ]}
                    handleDropdownChange={()=>{}}
                    label={"State/Province"}
                  />

                  <FuelsUserInputText
                    label={"Zipcode"}
                    changeHandler={()=>{}}
                  />
                  {/* add timezones dynamically once the country is selected */}
                  <FuelsDropdown
                    optionList={[{ name: "CST" }, { name: "PST" }]}
                    handleDropdownChange={()=>{}}
                    label={"Timezone"}
                  />
                </div>
              </FuelsExpandable>

              <FuelsExpandable headerText={"Business Parameter"}>
                <div>
                  <FuelsUserInputText
                    label={"Business Organization"}
                    changeHandler={()=>{}}
                  />
                  <FuelsUserInputText
                    label={"Business Type"}
                    changeHandler={()=>{}}
                  />
                  <FuelsUserInputText
                    label={"Zone Name"}
                    changeHandler={()=>{}}
                  />
                  <FuelsUserInputText
                    label={"SEI ID"}
                    changeHandler={()=>{}}
                  />
                </div>
              </FuelsExpandable>
            </div>
          )}

          {currentFormComponentIndex == 1 && (
            <div>
              <FuelsDropdown
                optionList={[
                  { name: "Verifone" },
                  { name: "Gilbarco" },
                  { name: "Bulloch" },
                  { name: "DEX" },
                  { name: "Others" },
                ]}
                handleDropdownChange={()=>{}}
                label={"POS Type"}
              />
              <FuelsUserInputText
                label={"POS Version"}
                changeHandler={()=>{}}
              />
              <FuelsUserInputToggle
                label={"Price Sign Integration"}
                onChange={()=>{}}
              />
              <FuelsUserInputToggle
                label={"Auto Init"}
                onChange={()=>{}}
              />
              <FuelsUserInputText label={"IP"} changeHandler={()=>{}} />
              <FuelsUserInputText
                label={"Username"}
                changeHandler={()=>{}}
              />
              <FuelsUserInputText
                label={"Password"}
                changeHandler={()=>{}}
                isPassword={true}
              />
              <FuelsUserInputText label={"Port"} changeHandler={()=>{}} />
              <FuelsUserInputText
                label={"Protocol"}
                changeHandler={()=>{}}
              />
              <FuelsUserInputText
                label={"Outbound"}
                changeHandler={()=>{}}
              />
              <FuelsUserInputText
                label={"Inbound"}
                changeHandler={()=>{}}
              />
              <FuelsExpandable headerText={"Store Network Provider"}>
                <div>
                  <FuelsDropdown
                    optionList={[
                      { name: "Cybera VPN" },
                      { name: "HughesNet" },
                      { name: "MNSP - Cybera" },
                      { name: "Horizon" },
                      { name: "Acumera Valero" },
                      { name: "Pending" },
                      { name: "Mako" },
                      { name: "DEX Cloud" },
                      { name: "Cybera" },
                      { name: "Sentex Cloud" },
                      { name: "HughesNet Chevron" },
                      { name: "HughesNet P66" },
                    ]}
                    handleDropdownChange={()=>{}}
                    label={"Network Provider"}
                  />
                  <FuelsUserInputText
                    label={"Network Ip"}
                    changeHandler={()=>{}}
                  />
                  <FuelsUserInputText
                    label={"Provide Support POC"}
                    changeHandler={()=>{}}
                  />
                </div>
              </FuelsExpandable>
            </div>
          )}

          {currentFormComponentIndex == 2 && (
            <div>
              <FuelsDropdown
                optionList={[
                  { name: "Transaction" },
                  { name: "Price Update" },
                  { name: "PostToA2I" },
                  { name: "Tank Inventory" },
                  { name: "Sales Summary" },
                  { name: "Price Update SchdEmail" },
                  { name: "Price Update Ack" },
                  { name: "Email Only Price Update" },
                ]}
                handleDropdownChange={()=>{}}
                label={"Business Function"}
              />
            </div>
          )}

          {currentFormComponentIndex == 3 && <ProductMapping />}

          {currentFormComponentIndex == 4 && <AlertConfiguration />}
        </div>
      </div>
      <div className={cssClasses.buttonContainer}>
        <FuelsButton
          text={"next"}
          isPrimary={false}
          onClick={() =>
            setCurrentFormComponentIndex(
              (currentFormComponentIndex + 1) % formComponent.length
            )
          }
        />
        <FuelsButton text={"reset"} isPrimary={false} onClick={()=>{}} />
        <FuelsButton text={"Submit"} isPrimary={true} onClick={()=>{}} />
      </div>
    </div>
  );
}
