// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n\n\n.zn4Z0WsGSHznyosO4xtO9w\\=\\={\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n.BoVaS3CCNQHKakzmmagMJg\\=\\={\n    display: flex;\n    flex-direction: row;\n    gap: 15px;\n    margin-top: 10px;\n}\n.GdC-w9Q-ZTg2NiZOqVv3jA\\=\\={\n    display: flex;\n    flex-direction: column;\n    /* flex-wrap: auto; */\n    width: max-content;\n    gap: 10px;\n    border: 1px solid rgb(146, 160, 148);\n    padding: 3px;\n    border-radius: 5px;\n}\n\n.GdC-w9Q-ZTg2NiZOqVv3jA\\=\\=>h6{\n    padding: 0px;\n    margin: 0px;\n}", "",{"version":3,"sources":["webpack://./src/components/tool-bar/drawerContentComponts/addSiteDrawer/productMapping/ProductMapping.module.css"],"names":[],"mappings":";;;AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,qBAAqB;IACrB,kBAAkB;IAClB,SAAS;IACT,oCAAoC;IACpC,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf","sourcesContent":["\n\n\n.checkboxANdButton{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n.inputFieldGroupsContainer{\n    display: flex;\n    flex-direction: row;\n    gap: 15px;\n    margin-top: 10px;\n}\n.inputFieldGroup{\n    display: flex;\n    flex-direction: column;\n    /* flex-wrap: auto; */\n    width: max-content;\n    gap: 10px;\n    border: 1px solid rgb(146, 160, 148);\n    padding: 3px;\n    border-radius: 5px;\n}\n\n.inputFieldGroup>h6{\n    padding: 0px;\n    margin: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxANdButton": "zn4Z0WsGSHznyosO4xtO9w==",
	"inputFieldGroupsContainer": "BoVaS3CCNQHKakzmmagMJg==",
	"inputFieldGroup": "GdC-w9Q-ZTg2NiZOqVv3jA=="
};
export default ___CSS_LOADER_EXPORT___;
