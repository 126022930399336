import React, { useEffect, useState } from "react";
import cssClasses from "./FuelsNavigation.module.css";

type Props = {
  size: number;
  showInAPage: number;
  currentRange: (start: number, end: number) => void;
};

function FuelsNavigation({ size, showInAPage, currentRange }: Props) {
  const [currentPageRange, setCurrentPageRange] = useState([
    0,
    showInAPage - 1,
  ]);
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);
  const lastDivisableNumber = parseInt("" + size / showInAPage) * showInAPage;
  
  const lastPageStartingLimit = lastDivisableNumber == size? lastDivisableNumber - showInAPage: lastDivisableNumber;
  useEffect(() => {
    currentRange(currentPageRange[0], currentPageRange[1]);
  }, [currentPageRange]);

  useEffect(()=>{
    if (showInAPage>=size){
      setIsFirstPage(true)
      setIsLastPage(true)
    }
  }, [size, showInAPage])

  function goToStart() {
    if(isFirstPage){
      return
    }
    setIsFirstPage(true);
    setIsLastPage(false);
    setCurrentPageRange([0, showInAPage - 1]);
  }
  function goToPrevious() {
    if(isFirstPage){
      return
    }
    if (isLastPage) {
      const start = lastPageStartingLimit - showInAPage;
      const end = lastPageStartingLimit - 1
      setCurrentPageRange([
        lastPageStartingLimit - showInAPage,
        lastPageStartingLimit - 1,
      ]);
      if(start===0){
        setIsFirstPage(true)
      }
      setIsLastPage(false);
      return;
    }
    if (isFirstPage) {
      return;
    }
    const start = currentPageRange[0] - showInAPage;
    const end = currentPageRange[1] - showInAPage;
    if (start == 0) {
      setIsFirstPage(true);
    }
    setCurrentPageRange([start, end]);
  }
  function goToNext() {
    if (isLastPage) {
      return;
    }
    setIsFirstPage(false);
    const start = currentPageRange[0] + showInAPage;
    const end = Math.min(currentPageRange[1] + showInAPage, size - 1);
    if (end === size - 1) {
      setIsLastPage(true);
    }
    setCurrentPageRange([start, end]);
  }
  function goToEnd() {
    if(isLastPage){
      return
    }
    // if (lastDivisableNumber == size) {
    // //   console.log("equal");
    //   setCurrentPageRange([lastDivisableNumber - showInAPage, size - 1]);
    // } else {
    //   setCurrentPageRange([lastDivisableNumber, size - 1]);
    // }
    setCurrentPageRange([lastPageStartingLimit, size - 1]);
    setIsLastPage(true);
    setIsFirstPage(false);
  }
  return (
    <div className={cssClasses.bottomNavigation}>
      <span
        onClick={goToStart}
        title="start"
        className={isFirstPage ? cssClasses.disabledNav : cssClasses.enabledNav}
      >
        {"<<"}
      </span>
      <span
        onClick={goToPrevious}
        title="previous"
        className={isFirstPage ? cssClasses.disabledNav : cssClasses.enabledNav}
      >
        {"<"}
      </span>
      <span>
        {currentPageRange[0] + 1} - {Math.min((currentPageRange[1]+ 1), size) } of {size}
      </span>
      <span
        onClick={goToNext}
        title="next"
        className={isLastPage ? cssClasses.disabledNav : cssClasses.enabledNav}
      >
        {">"}
      </span>
      <span
        onClick={goToEnd}
        title="end"
        className={isLastPage ? cssClasses.disabledNav : cssClasses.enabledNav}
      >
        {">>"}
      </span>
    </div>
  );
}

export default FuelsNavigation;
