// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Mxm1dj\\+KSz15mV\\+9CIZBQg\\=\\={\n    position: relative;\n    animation: _0uVtzsMPHxmbdijDMym57w\\=\\= 200ms ease-in-out;\n}\n\n@keyframes _0uVtzsMPHxmbdijDMym57w\\=\\= {\n    0% {\n        left: -100%;\n    }\n    100%{\n        left: 0px;\n    }\n}\n.SOay0\\+u5UoD0MAlV59xVIA\\=\\={\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    align-items: center;\n}\n._2wPC3O3ERMxYFlZdciFm0g\\=\\={\n    display: flex;\n    flex-direction: row;\n    justify-content: end;\n    margin-top: 20px;\n    gap: 10px;\n}\n\n._9A6kZTpN31LNydW1\\+swJzA\\=\\={\n    position: relative;\n    width: 100%;\n    bottom: 0px;\n    display: flex;\n    justify-content: space-between;\n    padding: 20px 2px 15px 2px;\n}", "",{"version":3,"sources":["webpack://./src/components/tool-bar/drawerContentComponts/advancedSearchDrawer/landmarkTab/LandmarkTab.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,wDAA4C;AAChD;;AAEA;IACI;QACI,WAAW;IACf;IACA;QACI,SAAS;IACb;AACJ;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,oBAAoB;IACpB,gBAAgB;IAChB,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,aAAa;IACb,8BAA8B;IAC9B,0BAA0B;AAC9B","sourcesContent":[".container{\n    position: relative;\n    animation: swipe-from-left 200ms ease-in-out;\n}\n\n@keyframes swipe-from-left {\n    0% {\n        left: -100%;\n    }\n    100%{\n        left: 0px;\n    }\n}\n.cardsContainer{\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    align-items: center;\n}\n.buttonContainer{\n    display: flex;\n    flex-direction: row;\n    justify-content: end;\n    margin-top: 20px;\n    gap: 10px;\n}\n\n.bottomNavigation{\n    position: relative;\n    width: 100%;\n    bottom: 0px;\n    display: flex;\n    justify-content: space-between;\n    padding: 20px 2px 15px 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Mxm1dj+KSz15mV+9CIZBQg==",
	"swipe-from-left": "_0uVtzsMPHxmbdijDMym57w==",
	"cardsContainer": "SOay0+u5UoD0MAlV59xVIA==",
	"buttonContainer": "_2wPC3O3ERMxYFlZdciFm0g==",
	"bottomNavigation": "_9A6kZTpN31LNydW1+swJzA=="
};
export default ___CSS_LOADER_EXPORT___;
