// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nOG19lULu-TexXMuc4nDtw\\=\\={\n    padding: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./src/components/tool-bar/drawerContentComponts/optionsDrawer/OptionsDrawer.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB","sourcesContent":[".content{\n    padding: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "nOG19lULu-TexXMuc4nDtw=="
};
export default ___CSS_LOADER_EXPORT___;
