// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XB6bME8rhL3wMVFJbuqTeg\\=\\={\n    position: relative;\n    width: 100%;\n    bottom: 0px;\n    display: flex;\n    justify-content: space-between;\n    padding: 20px 2px 15px 2px;\n}\n\n._2326fQUjuoVXKSqi1RrJ\\+Q\\=\\={\n    cursor: pointer;\n    color: black;\n}\n\n.zAmggjEG3LhqOlyJA0fzYA\\=\\={\n    color: rgb(134, 134, 134);\n    cursor: default;\n    /* pointer-events: none; */\n}", "",{"version":3,"sources":["webpack://./src/components/fuelsNavigation/FuelsNavigation.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,aAAa;IACb,8BAA8B;IAC9B,0BAA0B;AAC9B;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,0BAA0B;AAC9B","sourcesContent":[".bottomNavigation{\n    position: relative;\n    width: 100%;\n    bottom: 0px;\n    display: flex;\n    justify-content: space-between;\n    padding: 20px 2px 15px 2px;\n}\n\n.enabledNav{\n    cursor: pointer;\n    color: black;\n}\n\n.disabledNav{\n    color: rgb(134, 134, 134);\n    cursor: default;\n    /* pointer-events: none; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bottomNavigation": "XB6bME8rhL3wMVFJbuqTeg==",
	"enabledNav": "_2326fQUjuoVXKSqi1RrJ+Q==",
	"disabledNav": "zAmggjEG3LhqOlyJA0fzYA=="
};
export default ___CSS_LOADER_EXPORT___;
